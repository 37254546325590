<template>
  <c-box id="kuisioner">
    <Header />
    <c-box paddingTop="30px" paddingBottom="80px" :px="['20px', '0']">
      <c-box w="100%" maxWidth="1200px" mx="auto">
        <Breadcrumb
            :items="[
            {
              label: 'Beranda',
              href: $router.resolve({ name: 'client.index' }).href,
            },
            {
              label: 'Kuisioner',
              href: $router.resolve({ name: 'client.kuisioner' }).href,
              isCurrent: true,
            },
          ]"
        />
        <hr v-chakra mt="30px" />
        <Steps :current="4" :total="7" v-chakra mt="30px" />

        <c-box w="100%" maxWidth="590px" mx="auto" marginTop="50px">
          <c-box textAlign="center">
            <c-text
              color="brand.900"
              fontFamily="roboto"
              textTransform="uppercase"
              fontWeight="700"
              fontSize="16px"
              marginBottom="10px"
            >
              Kuisioner (4/7)
            </c-text>
            <c-heading
              fontWeight="600"
              fontSize="36px"
              lineHeight="54px"
              color="black.900"
            >
              Data Aktivitas Harian
            </c-heading>
          </c-box>
          <form @submit.prevent="submit">
            <c-box
              marginTop="80px"
              v-chakra="{
                'input[type=radio]:checked+.css-1nskzgf': {
                  bg: 'brand.900',
                  borderColor: 'brand.900',
                },
                'input[type=checkbox]:checked+.css-fd41dq': {
                  bg: 'brand.900',
                  borderColor: 'brand.900',
                },
                'div.css-huvqms': {
                  fontSize: '18px',
                  color: '#555555',
                },
              }"
            >
              <c-form-control
                marginBottom="30px"
                is-required
                :is-invalid="$v.activeLevel.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Bagaimana tingkat keaktifan kamu dalam sehari?
                </c-form-label>

                <c-select
                  height="62px"
                  placeholder="Bagaimana tingkat keaktifan kamu dalam sehari?"
                  v-model="activeLevel"
                  required
                  id="kuis-4-form-1"
                >
                  <option value="Aktivitas sangat ringan">
                    Aktivitas sangat ringan
                  </option>
                  <option value="Bedrest">Bedrest</option>
                  <option value="Kebanyakan duduk">Kebanyakan duduk</option>
                  <option value="Aktivitas sedang">Aktivitas sedang</option>
                  <option value="Aktivitas berat">Aktivitas berat</option>
                  <option value="Aktivitas sangat berat">
                    Aktivitas sangat berat
                  </option>
                </c-select>
                <c-form-helper-text
                  v-if="!$v.activeLevel.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>
              <c-form-control marginBottom="30px">
                <c-text
                  fontSize="15px"
                  color="brand.900"
                  fontWeight="500"
                  lineHeight="27px"
                  fontFamily="Roboto"
                >
                  Selama 1 minggu terakhir, pilih semua jenis aktivitas atau
                  olahraga yang sudah kamu lakukan dan durasinya
                </c-text>
              </c-form-control>
              <c-form-control
                marginBottom="30px"
                is-required
                :is-invalid="$v.sportType.$invalid"
                id="kuis-4-form-2"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Jenis olahraga yang dilakukan
                </c-form-label>
                <c-checkbox-group
                  size="lg"
                  variant-color="green"
                  spacing="2"
                  v-model="sportType_"
                  required
                >
                  <template v-for="item in sportTypesList_">
                    <c-checkbox
                      :key="item.type"
                      :value="item.type"
                      :is-disabled="
                        sportType_.includes('Tidak ada') &&
                        item.type !== 'Tidak ada'
                      "
                    >
                      {{ item.type }}
                    </c-checkbox>
                    <c-form-control
                      :key="`duration-${item.type}`"
                      v-if="
                        sportType_.includes(item.type) &&
                        item.type !== 'Tidak ada'
                      "
                      :is-invalid="
                        Object.values($v.sportType.$each.$iter).find(
                          (it) => it.$model.type === item.type
                        ).$invalid
                      "
                    >
                      <c-form-label
                        fontSize="16px"
                        color="#555555"
                        fontWeight="400"
                        fontFamily="Roboto"
                      >
                        Durasi
                      </c-form-label>
                      <c-select
                        height="62px"
                        placeholder="Duration"
                        v-model="durations[item.type]"
                        required
                      >
                        <option
                          value="Tidak melakukan atau kurang dari 10 menit"
                        >
                          Tidak melakukan atau kurang dari 10 menit
                        </option>
                        <option value="10 - 15 menit">10 - 15 menit</option>
                        <option value="16 - 30 menit">16 - 30 menit</option>
                        <option value="31 - 45 menit">31 - 45 menit</option>
                        <option value="46 - 60 menit">46 - 60 menit</option>
                        <option value="Lebih dari 60 menit">
                          Lebih dari 60 menit
                        </option>
                      </c-select>
                    </c-form-control>
                  </template>
                </c-checkbox-group>
                <c-form-helper-text
                  v-if="!$v.sportType.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Jenis olahraga harus diisi
                </c-form-helper-text>
                <template v-for="(value, key) in $v.sportType.$each.$iter">
                  <c-form-helper-text
                    :key="key"
                    v-if="!value.duration.required"
                    fontSize="12px"
                    color="red.500"
                  >
                    Durasi dari olahraga {{ value.$model.type }} harus diisi
                  </c-form-helper-text>
                </template>
              </c-form-control>

              <c-form-control
                marginBottom="30px"
                is-required
                :is-invalid="$v.weekdaysTypicalActivity.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Tipikal keaktifan kamu pada hari kerja (weekdays)
                </c-form-label>

                <c-select
                  height="62px"
                  placeholder="Tipikal keaktifan kamu pada hari kerja (weekdays)"
                  v-model="weekdaysTypicalActivity"
                  required
                  id="kuis-4-form-3"
                >
                  <option value="Dominan berbaring">Dominan berbaring</option>
                  <option value="Dominan duduk">Dominan duduk</option>
                  <option value="Banyak berdiri">Banyak berdiri</option>
                  <option value="Banyak jalan kaki">Banyak jalan kaki</option>
                </c-select>
                <c-form-helper-text
                  v-if="!$v.weekdaysTypicalActivity.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                marginBottom="30px"
                is-required
                :is-invalid="$v.weekendTypicalActivity.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Tipikal keaktifan kamu pada hari libur dan akhir pekan
                </c-form-label>

                <c-select
                  height="62px"
                  placeholder="Tipikal keaktifan kamu pada hari libur dan akhir pekan"
                  v-model="weekendTypicalActivity"
                  required
                  id="kuis-4-form-4"
                >
                  <option value="Dominan berbaring">Dominan berbaring</option>
                  <option value="Dominan duduk">Dominan duduk</option>
                  <option value="Banyak berdiri">Banyak berdiri</option>
                  <option value="Banyak jalan kaki">Banyak jalan kaki</option>
                </c-select>
                <c-form-helper-text
                  v-if="!$v.weekendTypicalActivity.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                marginBottom="30px"
                is-required
                :is-invalid="$v.averageSleepHours.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Berapa jam rata-rata durasi tidur per hari
                </c-form-label>
                <c-input
                  type="number"
                  placeholder="Berapa jam rata-rata durasi tidur per hari"
                  height="62px"
                  paddingRight="70px"
                  v-model="averageSleepHours"
                  required
                  id="kuis-4-form-5"
                />
                <c-form-helper-text
                  v-if="!$v.averageSleepHours.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                marginBottom="30px"
                is-required
                :is-invalid="$v.bedTime.$invalid"
              >
                <c-form-label
                  fontSize="16px"
                  color="#555555"
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  Jam berapa biasa kamu tidur?
                </c-form-label>

                <c-input
                  type="text"
                  placeholder="Jam berapa biasa kamu tidur?"
                  height="62px"
                  paddingRight="70px"
                  v-model="bedTime"
                  required
                  id="kuis-4-form-6"
                />
                <c-form-helper-text
                  v-if="!$v.bedTime.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Harus diisi
                </c-form-helper-text>
                <c-form-helper-text
                  v-if="!$v.bedTime.format"
                  font-size="12px"
                  color="red.500"
                >
                  Format waktu tidak benar (contoh: 21:52:43)
                </c-form-helper-text>
              </c-form-control>

              <DinamisForm v-model="questions" />

              <c-flex
                justifyContent="space-between"
                alignItems="center"
                marginTop="30px"
              >
                <c-button
                  left-icon="chevron-left"
                  backgroundColor="brand.900"
                  color="white"
                  borderRadius="100px"
                  variant="solid"
                  px="30px"
                  as="router-link"
                  :to="{ name: 'client.kuisioner3', params: $route.params }"
                >
                  Kembali
                </c-button>
                <c-button
                  right-icon="chevron-right"
                  backgroundColor="brand.900"
                  color="white"
                  borderRadius="100px"
                  variant="solid"
                  px="30px"
                  type="submit"
                  id="submit"
                >
                  Selanjutnya
                </c-button>
              </c-flex>
            </c-box>
          </form>
        </c-box>
      </c-box>
    </c-box>
    <Footer />
  </c-box>
</template>

<script>
import Header from "@/components/header/index.vue";
import Footer from "@/components/Footer.vue";
import { mapState } from "vuex";
import { numeric, required, requiredIf } from "vuelidate/lib/validators";
import Breadcrumb from "@/components/breadcrumb"
import Steps from "@/components/steps"
import DinamisForm from "@/views/client/kuisioner/dinamis-form"


export default {
  name: "kuisioner4",
  components: {
    Header,
    Footer,
    Breadcrumb,
    Steps,
    DinamisForm,
  },
  data() {
    return {
      activeLevel: null,
      weekdaysTypicalActivity: null,
      weekendTypicalActivity: null,
      averageSleepHours: null,
      bedTime: null,
      // ---
      sportTypesList_: [
        { type: "Tidak ada", duration: "" },
        { type: "Jalan santai", duration: "" },
        { type: "Jalan cepat", duration: "" },
        { type: "Naik turun tangga", duration: "" },
        { type: "Jogging", duration: "" },
        { type: "Senam Aerobic/Cardio dan sejenisnya", duration: "" },
        { type: "Strength Training dan sejenisnya", duration: "" },
        { type: "Zumba/HIIT dan sejenisnya", duration: "" },
        { type: "Yoga/Pilates dan sejenisnya", duration: "" },
        { type: "Sepeda", duration: "" },
        { type: "Renang", duration: "" },
        { type: "Futsal/Sepakbola/Tennis/Bulutangkis", duration: "" },
      ],
      sportType_: [],
      durations: {},
      // -- dinamis
      questions: [],
    };
  },
  computed: {
    ...mapState({
      kuisData: (s) => s.kuisioner.dailyActivities,
    }),
    sportType() {
      return this.sportType_.map((it) => ({
        type: it,
        duration: this.durations[it],
      }));
    },
    value() {
      return {
        activeLevel: this.activeLevel,
        sportType: this.sportType,
        weekdaysTypicalActivity: this.weekdaysTypicalActivity,
        weekendTypicalActivity: this.weekendTypicalActivity,
        averageSleepHours: this.averageSleepHours,
        bedTime: this.bedTime,
      };
    },
    answers() {
      return this.questions.map((it) => ({
        questionId: it.id,
        answer: it.answer,
        question: it.question,
      }))
    },
  },
  validations: {
    activeLevel: { required },
    weekdaysTypicalActivity: { required },
    weekendTypicalActivity: { required },
    averageSleepHours: { required, numeric },
    bedTime: {
      required,
      format: (v) => /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/.test(v),
    },
    sportType: {
      required,
      $each: {
        type: { required },
        duration: {
          required: requiredIf((model) => model.type !== "Tidak ada"),
        },
      },
    },
  },
  watch: {
    kuisData: {
      immediate: true,
      handler(val) {
        if (val == null) return;

        this.activeLevel = val.activeLevel;
        this.weekdaysTypicalActivity = val.weekdaysTypicalActivity;
        this.weekendTypicalActivity = val.weekendTypicalActivity;
        this.averageSleepHours = val.averageSleepHours;
        this.bedTime = val.bedTime;

        this.sportType_ = val.sportType.map((it) => it.type);
        this.durations = val.sportType.reduce(
          (acc, it) => ({
            ...acc,
            [it.type]: it.duration,
          }),
          {}
        );
      },
    },
    programId: {
      immediate: true,
      async handler(programId) {
        if (programId == null) return;

        await this.$store.dispatch("kuisioner/getV2ClientForCategory", "daily_activities")
        await this.$store.dispatch("kuisioner/getV2ClientDraft", programId)
        this.questions = this.$store.getters["kuisioner/questionFor"]("daily_activities")
      }
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      await this.$store
        .dispatch("kuisioner/setDailyActivities", {
          programId: this.$route.params.programId,
          isDraft: true,
          value: this.value,
          answers: this.answers,
        })
        .then(() => {
          return this.$router.push({
            name: "client.kuisioner5",
            params: this.$route.params,
          });
        })
        .catch((e) => {
          this.$errorToast({
            message: e.response?.data?.message ?? e.toString(),
          });
        });
    },
  },
};
</script>

<style></style>
